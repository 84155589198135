@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: #f5f3f2;
  font-family: "Open Sans", sans-serif;
  scrollbar-color: #e4e6ef transparent;
  scrollbar-width: thin;
}

.alignment {
  @apply w-95/100 max-w-7xl mx-auto;
}

.page {
  @apply relative h-screen grid place-items-center;
}

.input {
  @apply p-3 w-full text-sm text-gray-27 rounded bg-gray-f2 border border-gray-e8 outline-none placeholder:capitalize focus:bg-white focus:border-blue-primary focus:caret-blue-primary;
}

.table__cell {
  @apply bg-white px-4 py-6 whitespace-nowrap max-w-80 truncate;
}

.table__checkbox {
  @apply w-[18px] h-[18px] border-2 border-gray-e8 rounded cursor-pointer;
}

.pagination-button {
  @apply w-6 h-6 rounded duration-500 cursor-pointer;
}

.pagination-button:disabled {
  @apply cursor-not-allowed;
}

.pagination-button:hover:enabled {
  @apply bg-blue-primary text-white;
}

.backdrop {
  @apply w-screen h-screen bg-gray-4147 grid place-items-center fixed top-0 left-0 z-30 cursor-pointer;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 10;
}
